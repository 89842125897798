export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  Cart: 'specs.bookings.cart',
  HideCouponInFormPage: 'specs.bookings.HideCouponInFormPage',
  SendAddressToEcomCheckoutAndCart:
    'specs.bookings.sendAddressToEcomCheckoutAndCart',
  ChangePricingPlanNavigationToNewApi:
    'specs.bookings.ChangePricingPlanNavigationToNewApi',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  DeleteFullAddressFromCollapseFormValues:
    'specs.bookings.DeleteFullAddressFromCollapseFormValues',
  MultiServiceAppointment: 'specs.bookings.multiServiceAppointment',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  AddShouldCallConsistantQueryToGetScheduleAvailability:
    'specs.bookings.AddShouldCallConsistantQueryToGetScheduleAvailability',
  SplitJapaneseNamesOnForm: 'specs.bookings.SplitJapaneseNamesOnForm',
  AdditionalTimeSlotsInFormPlugin:
    'specs.bookings.AdditionalTimeSlotsInFormPlugin',
  ShouldCheckConflictsAtFormLoad:
    'specs.bookings.ShouldCheckConflictsAtFormLoad',
  SendFirstNameAsEmailWhenFIrstNameIsEmpty:
    'specs.bookings.SendFirstNameAsEmailWhenFIrstNameIsEmpty',
  AllowEditCartPopup: 'specs.bookings.AllowEditCartPopup',
  UseCustomDialogForCartModal: 'specs.bookings.UseCustomDialogForCartModal',
};
